.aboutWrapper {
  text-align: center;
  margin: 20px; /* Adjust the margin as needed */
  padding-top: 28px;
  padding: 15px;
}

.heading {
  margin-bottom: 24px;
  h1 {
    font-size: 55px;
    font-weight: bolder;
    color: #023353;
  }
}

.wrapperSection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow the items to wrap to the next line */
  padding-top: 30px;
}

.row {
  display: flex;
  align-items: center;
  width: 100%; /* Make sure the row takes full width */
  justify-content: space-around; /* Space out the columns evenly */
  margin-bottom: 20px; /* Adjust the margin between rows */
}

.column {
  flex: 1;
  margin: 0 10px; /* Adjust the margin between columns */
  position: relative;
  padding: 10px;
  color: #023353;
}

.column:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  height: 4.5cm;
  border-right: 2px solid #023353; /* Vertical line color */
}

.innerText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top_icon {
  margin-bottom: 7px;
}

.count {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 7px;
}

h3 {
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 18px;
  font-weight: 900;
}

@media screen and (max-width: 17in) {
  .heading {
    h1 {
      font-size: 50px;
    }
  }
}

@media screen and (max-width: 13in) {
  .aboutWrapper {
    padding: 5px;
  }
  .count {
    font-size: 26px;
  }
}

@media screen and (max-width: 768px) {
  .aboutWrapper {
    margin-top: 30px;
  }
  .heading {
    margin-bottom: 0px;
    h1 {
      font-size: 38px;
    }
  }
  .wrapperSection {
    flex-direction: column;
    padding-top: 10px;
  }
  .row {
    flex-direction: column;
    margin: 0; /* Remove default margin */
  }
  .column {
    padding: 50px 0px;
    margin-bottom: 0; /* Remove default margin */
    padding-bottom: 5px;
    position: relative;
  }
  .column:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 0%;
    right: 0;
    transform: translateY(-50%);
    height: 0;
  }
  .innerText h3 {
    font-size: 18px;
  }
  .top_icon {
    margin-bottom: 8px;
  }
  .count {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .top_icon {
    margin-bottom: 7px;
  }
}
