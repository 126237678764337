.footer {
    background-color: #023353;
    color: white;
    max-width: 100%;
    padding: 40px 0px;
    height: auto;
}

.footer-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 100%;
}

/* Style for each column in the footer */
.footer-column {
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    text-align: left;
}

/* Style for the social media icons */
.footer-icons i {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 18px;
    background-color: #023353;
    color: white;
    border-radius: 50%;
    border: 1px solid white;
    padding: 0.5rem;
    margin: 0 0.4rem;
}

.footer-icons i:hover {
    background-color: white;
    color: #023353;
    cursor: pointer;
}

.footer-column li{
    list-style: none;
    padding-bottom: 10px;
    transition: color 0.5s;
    font-size: 15px;
}

.footer-column a:hover {
    color: white;
    cursor: pointer;
}

.footer-column a {
    text-decoration: none;
    color: #ccc;
    font-family: 'Noto Sans JP', sans-serif;
}

/* Style for Contact Us in column-3 */
.footer-column h5 {
    color: white;
    padding-bottom: 25px;
    font-weight: 400;
    font-size: 21px;
}

.footer-column p {
    margin: 5px 0;
    padding-bottom: 10px;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 14px;
    color: #ccc;
}

/* Style for the last footer section */
.Last-footer {
    background-color: #023353;
    color: #fff;
    text-align: left;
    padding: 20px 0px;
    font-size: 14px;

    p{
        padding-left: 20px;
        font-family: 'Noto Sans JP', sans-serif;
    }
}

.Last-footer hr{
    color: #ccc;
    margin-bottom: 15px;
}

.footer-icons{
    margin-top: 8px;
}

@media screen and (max-width: 768px){
    .footer {
        user-select: none;
        padding: 20px 0px;
    }
    .footer-row {
        flex-direction: column;
    }
    .footer-icons i {
        font-size: 19px;
    }
    .footer-column {
        padding: 10px 20px;
    }
    .Last-footer p{
        font-size: 13px;
    }
}