.whyLsit_container {
    max-width: 1290px; /* Adjust the maximum width as needed */
    margin: 0 auto;   /* Center the container horizontally */
    padding-top: 22px;
    text-align: justify; /* Justify the text for equal line lengths */
    background-color: white;
    margin-top: 8px;
    padding-bottom: 10px;
}

.whyLsit_container h1 {
    text-align: center;
    color: #023353; /* Change the color to your preferred color */
    margin-bottom: 5px; /* Add space below the heading */
    font-size: 55px;
    font-weight: bolder;
    padding-bottom: 22px;
    cursor: default;
}

.whyLsit_container p {
    color: black;
    font-size: 18px;
    line-height: 1.7;
    word-spacing: 2px; /* Add spacing between words */
    font-weight: 450;
    font-family: 'Noto Sans JP', sans-serif;
    padding-top: 0px;
}
.paragraph p{
    margin: 5px;
}


@media screen and (max-width: 17in){
        .whyLsit_container {
            max-width: 80%; /* Adjust the maximum width as needed for smaller screens */
            padding-top: 15px; /* Adjust padding for smaller screens */
            margin-top: 5px; /* Adjust margin for smaller screens */
        }
    
        .whyLsit_container h1 {
            font-size: 49px; /* Adjust the font size for smaller screens */
            padding-bottom: 17px; /* Adjust padding for smaller screens */
        }
    
        .whyLsit_container p {
            font-size: 16px; /* Adjust the font size for smaller screens */
        }
}

@media screen and (max-width: 13in){
    .whyLsit_container p{
        font-size: 15px;
    }
}

@media screen and (max-width: 768px){
    .whyLsit_container {
        max-width: 80%; /* Adjust the maximum width as needed */
        padding-top: 15px;
    }
    
    .whyLsit_container h1 {
        font-size: 35px;
        padding-bottom: 10px;
    }
    .whyLsit_container p {
        font-size: 14px;
        line-height: 1.4;
        word-spacing: 1px; /* Add spacing between words */
    }
}