.img-container{
    padding-top: 50px;

  .slick-list {
    overflow: hidden;
  }
}

.wrap{
  border-radius: 0px;
  cursor: default;
  position: relative;
  border-radius: 0px;
  cursor: default;
  display: block;
  position: relative;
  padding: 0px;

    img {
      width: 100%;
      height: auto;
      pointer-events: none;
      display: block;
    }
}
@media screen and (max-width: 768px)
{
  .img-container {
    padding-top: 0px;
  }

  .slick-list {
    overflow: hidden;
    height: auto; /* Adjust the height as needed for smaller screens */
  }

  .wrap {
    padding-top: 50px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
