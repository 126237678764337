.our-community-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    margin-top: 30px;
    background-color: #f8f8f8;
}

.community-title {
    flex-basis: 100%;
    text-align: center;
    font-size: 55px;
    font-weight: bolder;
    color: #023353;
    padding-bottom: 0px;
    margin-top: 40px;
}
  
.community-images-container {
    display: flex;
    flex-direction: row;
    padding: 300px;
    padding-top: 45px;
    padding-bottom: 100px;
    column-gap: 10px;
}
  
.community-column {
    flex: 1;
    display: flex;
    flex-direction: column;
}
  
.community-row {
    display: flex;
    column-gap: 15px;
}
  
.community-column a, .community-row a {
    flex: 1;
    margin: 5px;
    max-width: 100%; 
}
  
.community-images-container img {
    width: 100%;
    height: auto;
    display: block;
    border: 4px solid #023353;
    object-fit: cover;
}

@media screen and (max-width: 17in){
    .community-title{
        font-size: 50px;
    }
    .community-images-container{
        padding: 100px;
        padding-top: 35px;
        padding-bottom: 75px;
    }
}

@media screen and (max-width: 768px){
    .community-title {
        font-size: 33px;
        margin-top: 30px;
    }
    .community-images-container {
        flex-direction: column;
        padding: 0px 60px;
        padding-top: 35px;
        padding-bottom: 80px;
    }
    .community-images-container img {
        border: 3px solid #023353;
    }
}