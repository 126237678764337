body, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #023353;
    padding: 6px;
    z-index: 1000;
    position: fixed;
    width: 100%;
  }
  .nav-logo {
    display: flex;
    align-items: center;
    color: white;
    margin-left: 5px;
    cursor: pointer;
    text-decoration: none;
  }
.nav-logo p {
    font-size: 19px;
    margin: 0;
    cursor: pointer;
  }
  .nav-logo img {
    width: 60px;
    height: auto;
    margin-right: 10px; 
  }
  .nav-menu {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 500;
    font-size: 19px;
  }
  .nav-menu li {
    margin-right: 30px;
    text-decoration: none;
    color: white;
    cursor: default;
  }
.nav-item {
    position: relative;
    margin-right: 20px;
    color: #fff;
  }
.submenu {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    top: 41px;
    left: -25px;
    width: 200px;
    background-color: white;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
    font-size: 15px;
  }
  .nav-item:hover .submenu {
    opacity: 1;
    max-height: 600px; /* Set a value large enough to accommodate your submenu content */
  }
  .submenu li {
    position: relative; /* Added position relative */
    padding: 12px;
    border-bottom: 1px solid black;
    color: black;
    transition: background-color 0.1s ease, color 0.1s ease;
    width: 200px;
    cursor: pointer;
  }
.submenu li a {
    display: block; /* Make the anchor tag a block element to cover the entire li */
    width: 100%; /* Make the anchor tag cover the entire width of the li */
    height: 100%; /* Make the anchor tag cover the entire height of the li */
    text-decoration: none;
    color: black;
  }
  .submenu li:hover {
    background-color: #023353;
    color: white;
    transition: background-color 0s ease, width 0s ease; /* Added background-color and width transitions */
    a{
      color: white;
      cursor: pointer;
    }
  }
  .submenu li:last-child {
    border-bottom: none;
  }
.nav-item a {
    text-decoration: none;
    color: white;
    cursor: default;
}
@media screen and (max-width: 13in){
  .nav-logo p{
    font-size: 16px;
  }
  .nav-menu{
    font-size: 16px;
  }
}
.mobile-menu-toggle {
  display: none;
  font-size: 20px;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
  margin-right: 22px;
  font-weight: bolder;
}
@media screen and (max-width: 768px) {
  .navbar{
    padding: 12px;
  }
  .nav-logo{
    margin-left: 0px;
  }
  .nav-logo img {
    width: 30px; 
    margin-right: 5px;
  }
  .nav-logo p {
    font-size: 12px;
    font-weight: 400;
  }
.mobile-menu-toggle {
    display: block;
  }
  .nav-menu {
    display: none;
    flex-direction: column;
    padding-top: 15px;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: #023353;
    z-index: 1;
    font-size: 14px;
  }
  .nav-item {
    padding-left: 18px;
    padding-bottom: 12px;
    user-select: none;
  }
  .submenu {
    top: 0px;
    left: 120px;
    width: 200px;
    font-size: 13px;
    z-index: 2;
  }
  .submenu li{
    user-select: none;
    z-index: 3;
  }
  .nav-menu.mobile-menu-open {
    display: flex;
  }
}