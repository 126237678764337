.chairmanMsg_container {
    background-color: #022a44;
    position: relative;
    box-sizing: border-box;
    max-width: 100%;
    padding: 0px 50px;
    height: 370px;
    overflow: hidden;
}

.top-content {
    margin-left: 107px;
    margin-right: auto;
    max-width: 1050px;
    text-align: left;
    padding-left: 193px;
    color: white;
    padding-top: 45px;

    h1{
        font-size: 49px;
        font-weight: 700;
    }
}

.chairmanMsg{
    display: flex;
    align-items: center;
}

.directorImage {
    margin-left: 100px;
    margin-top: 0px;
}

.directorImage img {
    border-radius: 50%;
    height: 165px; /* Set the desired height */
    width: 165px; /* Set the desired width */
    z-index: -1; /* Move the image behind other elements */
}

.bottom-content{
    margin-left: 20px;
    padding: 20px;
    font-family: 'Noto Sans JP', sans-serif;
    color: white;
    margin-top: 16px;
}

.bottom-content p{
    font-size: 17px;
    line-height: 28px;
    text-align: justify;
    word-spacing: 1px;
    width: 1300px;
}

.bottom-content h2{
    font-size: 17px;
}

.details{
    padding-top: 20px;
    h2{
        font-size: 20px;
    }
    h6{
        font-size: 15px;
        font-weight: lighter;
    }
}


@media screen and (max-width: 17in){
    .chairmanMsg_container{
        max-width: 100%;
        padding: 0px 0px;
    }

    .top-content{
        padding-top: 40px;
        h1{
            font-size: 46px;
        }
    }

    .bottom-content p{
        font-size: 15px;
        width: 100%;
        margin: 0px;
        line-height: 25px;
    }

    .bottom-content{
        margin-right: 80px;
        margin-top: 4px;
    }

    .details{
        padding-top: 15px;
    }
}

@media screen and (max-width: 13in){
    .bottom-content p{
        font-size: 14px;
    }
    .bottom-content{
        margin-right: 60px;
        margin-top: 0px;
    }
    .details{
        padding-top: 10px;
    }
}

@media screen and (max-width: 768px){
    .chairmanMsg_container {
        max-width: 100%;
        padding: 0px 0px;
        height: auto;
        padding-bottom: 40px;
    }
    .top-content {
        margin: 0px;
        max-width: 100%;
        text-align: center;
        padding-left: 0px;
        h1{
            font-size: 32px;
            font-weight: bolder;
        }
    }
    .chairmanMsg{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .directorImage {
        margin: 0px;
        padding: 28px 0px;
    }
    .directorImage img {
        height: 120px; /* Set the desired height */
        width: 120px; /* Set the desired width */
    }
    .bottom-content{
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    } 
    .bottom-content p{
        font-size: 14px;
        line-height: 1.6;
        text-align: justify;
        word-spacing: 0;
        width: 80%;
    }
    .details{
        padding-top: 20px;
        h2{
            font-size: 20px;
        }
    }   
}