.faculty-details-container {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-bottom: 80px;
}

.university-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: #023353;
  color: white;
  margin-bottom: 15px;
  padding-top: 18px;
  padding-bottom: 18px;
}

.university-logo{
  padding-left: 35px;
}

.university-headings{
  padding-top: 0px;
  padding-left: 20px;
  p{
    padding-top: 4px;
  }
}

.university-logo img {
  width: 80px;
  height: auto;
}

.faculty-details{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 45px;
}

.faculty-details-left{
  padding-left: 60px;
}

.faculty-details-left img {
  width: 200px; /* Adjust the size as needed */
  border-radius: 0%;
  border: 3px solid #023353;
}

.faculty-details-right {
  text-align: left;
  padding-left: 35px;
  padding-top: 23px;
  color: #023353;
}

.faculty-details-right h1 {
  margin-bottom: 10px;
  font-size: 42px;
}

.faculty-details-right p {
  margin: 5px 0;
  font-size: large;
  font-weight: 500;
}

.faculty-details-right a {
  color: #023353;
  text-decoration: none;
}

.faculty-details-right a:hover {
  text-decoration: underline;
}

/* New styles for Subjects Taught and Area of Interest */
.extra-faculty-details{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 60px;
  padding-top: 20px;
}

.subjects-taught {
  margin-top: 20px;
}

.subjects-taught h1 {
  color: #023353;
  font-size: 38px;
  font-weight: bolder;
}

.subjects-taught ul {
  padding-top: 20px;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  padding-left: 45px;
  li{
    list-style: disc;
  }
}

.area-of-interest {
  margin-top: 20px;
}

.area-of-interest h1 {
  color: #023353;
  font-size: 38px;
  font-weight: bolder;
}

.area-of-interest ul {
  padding-top: 20px;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  padding-left: 45px;
  li{
    list-style: disc;
  }
}

.about{
  margin-top: 20px;
}

.about h1{
  color: #023353;
  font-size: 38px;
  font-weight: bolder;
}

.about p{
  padding-top: 20px;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  padding-left: 35px;
  padding-right: 35px;
  line-height: 1.6;
  word-spacing: 2px;
}

@media screen and (max-width: 768px){
  .university-logo img {
    width: 60px;
    height: auto;
  }
  .university-logo{
    padding-left: 20px;
  }
  .university-headings{
    padding-left: 10px;
    h1{
      font-size: 23px;
    }
    p{
      font-size: 15px;
    }
  }
  .faculty-details-left{
    padding-left: 5px;
  }
  .faculty-details{
    padding-top: 30px;
  }
  .faculty-details-left img {
    width: 160px; /* Adjust the size as needed */
    height: auto;
  }
  .faculty-details-right {
    padding-left: 10px;
    padding-top: 23px;
    color: #023353;
  }
  .faculty-details-right h1 {
    margin-bottom: 10px;
    font-size: 20px;
  }
  .faculty-details-right p {
    font-size: 13px;
  }
  .extra-faculty-details{
    padding-left: 10px;
    padding-top: 10px;
  }
  .subjects-taught h1 {
    font-size: 25px;
  }
  .subjects-taught ul {
    padding-top: 15px;
    font-size: 15px;
  }
  .area-of-interest h1 {
    font-size: 25px;
    }
  .area-of-interest ul {
    padding-top: 15px;
    font-size: 15px;
  }
  .about h1{
    font-size: 25px;
  }
  .about p{
    padding-top: 15px;
    font-size: 15px;
  }
}