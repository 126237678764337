.BSCS-container {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 40px;
}
  
.main-banner {
    background-color: #023353; /* Set your preferred background color */
    color: white; /* Set your preferred text color */
    padding: 50px;
    text-align: center;
    font-size: 22px;
    font-weight: bolder;
}
  
.sub-content {
    margin: 20px;
    margin-bottom: 0px;
    text-align: justify;
    padding-top: 10px;
    padding: 20px 250px;
}
  .sub-content h2{
    font-size: 30px;
    font-weight: bold;
    color: #023353;
    word-spacing: 2px;
}

.sub-content p{
    font-family: 'Noto Sans JP', sans-serif;
    line-height: 1.6;
    word-spacing: 2px;
    font-size: 16px;
    padding-top: 12px;
}

.sub-content ul {
    margin-bottom: 10px;
    padding-left: 20px; /* Adjust as needed */
    padding-top: 14px;
}

.sub-content li {
    list-style-type: disc;
    font-family: 'Noto Sans JP', sans-serif;
    word-spacing: 2px;
    font-size: 16px;
    margin-bottom: 6px; /* Adjust as needed */
  }
  
  .button {
    text-align: center;
    margin: 40px;
  }
  
  .button a {
    text-decoration: none;
  }
  
  .button button {
    padding: 10px 20px;
    font-size: 17px;
    background-color: #125684;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button button:hover {
    background-color: #023353;
  }

  @media screen and (max-width: 17in){
    .sub-content{
      padding: 20px 150px;
    }
  }

  @media screen and (max-width: 13in){
    .main-banner{
      font-size: 20px;
    }
    .sub-content p{
      font-size: 15px;
    }
    .sub-content li{
      font-size: 15px;
    }
  }

  @media screen and (max-width: 768px){
  .main-banner{
    padding: 40px 0px !important;
  }
  .main-banner h1{
    font-size: 19px;
  }
  .sub-content {
    padding: 15px 0px !important;
  }
  .sub-content h2{
    font-size: 19px !important;
  }
  .sub-content p{
    line-height: 1.5 !important;
    font-size: 14px !important;
}

.sub-content li {
    word-spacing: 1px !important;
    font-size: 14px !important;
  }
  .button button {
    font-size: 15px !important;
  }
  }