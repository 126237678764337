.banner-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 60px;
}

.banner-column {
  display: flex;
  flex-wrap: wrap; /* Ensure items wrap */
  justify-content: center;
  gap: 40px 25px; /* Add gap between the items */
}

.banner {
  width: calc(33.33% - 20px); /* Each banner takes up a third of the row, minus the gap */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.program-container {
  width: calc(90% - 10px); /* Adjust width and gap */
  margin: 10px; /* Add margin around each program container */
  text-align: center;
  background-color: #f8f8f8;
  padding-bottom: 15px;
}

.main-heading {
  width: 100%;
  margin: 0;
  text-align: center;
  margin-bottom: 20px; /* Reduce bottom margin */
  font-size: 55px; /* Adjust font size */
  font-weight: bolder;
  color: #023353;
  padding-bottom: 17px;
  cursor: default;
}

.program-container img {
  width: 100%;
  height: 270px;
  object-fit: cover;
  border-radius: 3px; /* Add rounded corners to the images */
}

.program-container h3 {
  margin-top: 14px;
  font-size: 21px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  color: #023353;
  font-weight: bolder;
}

.program-container a {
  display: inline-block;
  text-align: center;
  margin-top: 19px;
  text-decoration: none;
  overflow: hidden;
}

.program-container button {
  background-color: #125684;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  cursor: pointer;
  font-family: 'Noto Sans JP', sans-serif;

  &:hover {
    background-color: #023353; /* Darker green on hover */
}
}

@media screen and (max-width: 17in){
  .banner-container {
    margin-top: 22px;
    margin-bottom: 50px;
  }

  .banner {
    width: calc(28% - 10px); /* Adjust width and gap for smaller screens */
  }

  .program-container {
    width: calc(99% - 20px); /* Adjust width and gap for smaller screens */
    padding-bottom: 10px; /* Adjust padding for smaller screens */
  }

  .main-heading {
    font-size: 50px; /* Adjust font size for smaller screens */
    margin-bottom: 10px; /* Reduce bottom margin for smaller screens */
    padding-bottom: 12px; /* Adjust padding for smaller screens */
  }

  .program-container h3 {
    margin-top: 10px; /* Adjust margin-top for smaller screens */
    font-size: 17px; /* Adjust font size for smaller screens */
  }

  .program-container a {
    margin-top: 17px; /* Adjust margin-top for smaller screens */
  }

  .program-container button {
    padding: 7px 13px; /* Adjust padding for smaller screens */

    &:hover {
      background-color: #023353; /* Darker green on hover for smaller screens */
    }
  }
}

@media screen and (max-width: 13in){
    .program-container h3{
      font-size: 14px;
    }
    .program-container button{
      padding: 4px 7px;
      font-size: 13px;
    }
}

@media screen and (max-width: 768px){
  .banner-container {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .banner-column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  .banner {
    width: 80%; /* Adjust width and gap */
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .main-heading {
    margin-bottom: 10px; /* Reduce bottom margin */
    font-size: 30px; /* Adjust font size */
    padding-bottom: 10px;
  }
  .program-container button{
    font-size: 14px;
  }
}