.academic-faculty-container {
  display: flex;
  flex-direction: column;
}

.academic-faculty-section {
  background-color: white;
  color: black;
  text-align: center;
  margin-bottom: 10px;
}

.academic-faculty-section h2 {
  background-color: #023353; /* Set your preferred background color */
  color: white; /* Set your preferred text color */
  padding: 50px;
  text-align: center;
  font-size: 39px;
  font-weight: bolder;
}

.academic-faculty-row {
  display: flex;
  justify-content: center;
  gap: 95px;
  flex-wrap: wrap;
  padding-top: 50px;
  padding-bottom: 35px;
  font-family: "Noto Sans JP", sans-serif;
}

.academic-faculty-card {
  width: calc(25% - 25px);
  box-sizing: border-box;
  padding: 15px;
  border-radius: 0px;
  text-align: center;
  background-color: #fafafa;
}

.academic-faculty-card img {
  height: 170px;
  width: 170px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.academic-faculty-card h3 {
  margin: 0;
  font-size: 1.2em;
  font-weight: bolder;
}

.academic-faculty-card p {
  margin-top: 5px;
  padding: 2px;
}

.academic-faculty-card button {
  background-color: #125684;
  color: white;
  padding: 6px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 16px;
  font-family: "Noto Sans JP", sans-serif;
  a {
    text-decoration: none;
    color: white;
  }

  &:hover {
    background-color: #023353; /* Darker green on hover */
  }
}

@media screen and (max-width: 768px){
  .academic-faculty-row{
    flex-direction: column;
    align-items: center;
  }
  .academic-faculty-section h2 {
    padding: 40px 30px;
    font-size: 32px;
  }
  .academic-faculty-card {
    width: 80%;
  }
}
