.scholarship-container {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 60px;
}
  
.scholarship-main-banner {
    background-color: #023353; /* Set your preferred background color */
    color: white; /* Set your preferred text color */
    padding: 50px;
    text-align: center;
    font-size: 22px;
    font-weight: bolder;
}
  
.scholarship-sub-content {
    margin: 20px;
    margin-bottom: 0px;
    text-align: justify;
    padding-top: 23px;
}
.scholarship-sub-content h2{
    font-size: 32px;
    font-weight: bolder;
    color: #023353;
    word-spacing: 2px;
}

.scholarship-sub-content p{
    font-family: 'Noto Sans JP', sans-serif;
    word-spacing: 2px;
    font-size: 16px;
    padding-top: 12px;
}

@media screen and (max-width: 13in){
    .scholarship-main-banner{
        font-size: 20px;
    }
    .scholarship-sub-content h2{
        font-size: 30px;
    }
    .scholarship-sub-content p{
        font-size: 15px;
    }
  }

  @media screen and (max-width: 768px){
    .scholarship-main-banner {
        padding: 40px 10px;
        font-size: 15px;
    }
    .scholarship-sub-content {
        padding-top: 20px;
    }
    .scholarship-sub-content h2{
        font-size: 22px;
        word-spacing: 1px;
    }
    
    .scholarship-sub-content p{
        word-spacing: 0px;
        font-size: 15px;
        padding-top: 12px;
    }
  }